/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-underscore-dangle */
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import isNumber from "lodash/isNumber";
import union from "lodash/union";
import { type ZodEffects, type ZodObject } from "zod";
import { findKey } from "@utils/formatting";

interface useFormParamType<T> {
  /** Takes in a zod object or zod refined object */
  zod: ZodObject<any> | ZodEffects<ZodObject<any>>;
  data: T;
}

/**
 * @description
 * useForm is a custom hook that is used to handle form submissions.
 * @params zod - the zod object schema to be used for validation
 * @returns {function} validate - a function that takes in an id and a value to be validated.
 * @returns {function} onSubmitValidate - a function that validates all elements in the form based on zod schema.
 * @returns {object} onSubmitErrors - an object containing the errors for each element in the form based on zod schema. Requires onSubmit function to trigger.
 * @returns {function} clearErrors - a function that clears onSubmitErrors.
 */

export const useForm = <T extends Record<string, any>>({
  zod,
  data,
}: useFormParamType<T>) => {
  const { t } = useTranslation();
  const [onSubmitErrors, setOnSubmitErrors] =
    useState<Record<keyof T, string[]>>();

  const refs = useRef<Array<HTMLInputElement | null>>([]);
  const schemaKeys: string[] =
    "keyof" in zod
      ? zod.keyof()._def.values
      : zod._def.schema.keyof()._def.values;

  const subsetSchema = (key: string) =>
    "pick" in zod
      ? zod.pick({ [key]: true })
      : zod._def.schema.pick({ [key]: true });

  // zod middleware to customise default error messages
  const middleware = () => {
    schemaKeys.forEach((key) => {
      const singleSchema = subsetSchema(key);
      if (singleSchema) {
        const { shape } = singleSchema._getCached();
        const schemaDefinitions = findKey("checks", shape) as
          | Array<{
              kind: string;
              value: number;
              message?: string;
            }>
          | undefined;
        if (schemaDefinitions && schemaDefinitions.length > 0) {
          for (let i = 0; i < schemaDefinitions.length; i += 1) {
            const schemaCheck = schemaDefinitions[i];
            const hasCustomMessage = "message" in schemaCheck;
            // custom message goes here
            if (schemaCheck.kind === "min" && !hasCustomMessage) {
              schemaCheck.message =
                t("common:shared.required") ?? "This field is required";
            }
            if (schemaCheck.kind === "max" && !hasCustomMessage) {
              schemaCheck.message =
                t("common:shared.reachMaxLength", {
                  count: schemaCheck.value,
                }) ??
                `This field cannot be longer than ${schemaCheck.value} characters`;
            }
          }
        }
      }
    });
  };

  middleware();
  return {
    /**
     * @param node - the node to be added to the refs array
     * @example ref={ref}
     */
    ref: <T>(node: T extends HTMLInputElement ? T : null) => {
      // same reference equality check
      refs.current = union(refs.current, [node]);
    },

    /**
     * @description validate function that takes in an id and a value to be validated, returns custom default error messages.
     * @params id - the id of the element to be validated
     * @params value - the value of the element to be validated
     */
    validate: (key: string, value?: string) => {
      if (!zod) return "";
      const checkValue = isNumber(value) ? +value : value ?? "";
      const singleSchema = subsetSchema(key);
      const result = singleSchema.safeParse({
        [key]: checkValue,
      });
      if (result && !result.success) {
        const {
          error: { issues: data },
        } = result;
        return data[0].message;
      }
      return "";
    },

    onSubmitValidate: () => {
      if (refs.current.length > 0) {
        refs.current.forEach((element) => {
          if (!element) return;
          element.focus();
          element.blur();
          element.scrollIntoView({
            block: "nearest",
            inline: "center",
          });
        });
        // clean up
        refs.current = [];
      }
      const result = zod.safeParse(data);
      const allIssues = {} as Record<keyof T, string[]>;
      if (result && !result.success) {
        const { issues } = result.error;
        issues.forEach((issue) => {
          const { path, message } = issue;
          const pathKey = path[0] as keyof T;
          if (path?.length > 0)
            allIssues[pathKey] = [...(allIssues[pathKey] ?? []), message];
        });
      }
      schemaKeys.forEach((key: keyof T) => {
        if (!(key in allIssues)) allIssues[key] = [];
      });
      setOnSubmitErrors(allIssues);
      return { success: result.success, issues: allIssues };
    },

    onSubmitErrors,

    clearErrors: () => setOnSubmitErrors(undefined),
  };
};
